import React from 'react'

const TopRoutesGrid = ({grid_image ,heading ,rates}) => {
    return (
        <div className='grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-2  gap-x-3 sm:gap-y-2 md:gap-y-3  items-center px-4 py-5 bg-[#ffffff] justify-around rounded-md'>
            <img src={grid_image} alt="place_image" className='w-[130px] h-[90px] object-cover rounded-md'/>
            <div className=' flex flex-col justify-between'>
                <h4 className='text-[18px]'>{heading}</h4>
                <p className='text-[#555] mt-2'>One way Taxi</p>
                <span className='text-[#e30d16] text-[18px] mt-3 inline-block font-medium'>{rates}</span>
                <a  href="tel:+919443698657"  className='th-btn mt-3 call_btn'>CALL NOW</a>
            </div>
        </div>
    )
}

export default TopRoutesGrid