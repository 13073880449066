import React from "react";
import img1 from "../../assets/images/ser1.png";
import img2 from "../../assets/images/ser2.png";
import img3 from "../../assets/images/ser3.png";
import img4 from "../../assets/images/ser4.png";
const OurServices = () => {
  return (
    <div>
      <div className="container py-5 mx-auto px-4 md:px-8 lg:px-16">
        <h4 className="text-[#241D92] text-lg font-medium primary-color text-center">
          Our Service
        </h4>
        <h3 className="mt-4 font-semibold text-3xl">
          "I Drop Taxis: The Best Choice for Drop Taxi Services in South India"
        </h3>
        <div className="flex flex-col gap-y-[10px] pt-4">
          <p>
            At I Drop Taxis, we are committed to providing efficient and
            reliable drop taxi services for our customers. We understand the
            importance of punctuality and convenience when it comes to drop taxi
            services, and that is why we strive to make every journey with us a
            hassle-free and comfortable experience.
          </p>
          <p>
            Our fleet of well-maintained vehicles and experienced drivers ensure
            that you reach your destination on time and in comfort. We offer
            transparent pricing with no hidden charges, and our payment options
            are flexible and convenient.
          </p>
          <p>
            We offer various drop taxi services, including one-way drop and
            round-trip options, to suit your requirements. Our customer support
            team is available 24/7 to assist you with your bookings and queries,
            and we ensure that your feedback and suggestions are taken into
            account to constantly improve our services.
          </p>
          <p>
            At I Drop Taxis, we prioritize the safety of our passengers and
            adhere to all safety protocols and guidelines. Our drivers are
            trained to follow traffic rules and maintain safe driving practices.
            We also ensure that our vehicles are regularly sanitized and
            equipped with safety features such as seat belts, airbags, and GPS
            tracking.
          </p>
          <p>
            Our customer support team is available 24/7 to assist you with your
            bookings and queries. You can easily book your cab online through
            our website or mobile app. We offer transparent pricing with no
            hidden charges and provide various payment options for your
            convenience.
          </p>
          <p>
            Choose I Drop Taxis for reliable and efficient drop taxi services
            in South India, and experience hassle-free travel at its best.
          </p>
        </div>
      </div>
      <div className="  px-0 sm:px-4 md:px-8 lg:px-16 bg-[#f5f5f5]  py-3 md:py-5 mt-4 md:mt-6 lg:mt-8">
        <div className="container grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-2  text-center">
          <div className=" flex flex-col gap-y-1">
            <img src={img1} className=" w-[80px] mx-auto" />
            <p className=" text-lg md:text-xl font-semibold">
              Easy Online Booking
            </p>
            <p>Pickup and Drop on time</p>
          </div>
          <div className=" flex flex-col gap-y-1">
            <img src={img2} className=" w-[80px] mx-auto" />
            <p className=" text-lg md:text-xl font-semibold">
            Professional Drivers
            </p>
            <p>Well Trained years of experience</p>
          </div>
          <div className=" flex flex-col gap-y-1">
            <img src={img3} className=" w-[80px] mx-auto" />
            <p className=" text-lg md:text-xl font-semibold">
            Variety of Prime Brands
            </p>
            <p>Benz - BMW - Audi</p>
          </div>
          <div className=" flex flex-col gap-y-1">
            <img src={img4} className=" w-[80px] mx-auto" />
            <p className=" text-lg md:text-xl font-semibold">
            Secure Online Payment
            </p>
            <p>24 /7 Customer Support</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurServices;
