import React, { useEffect, useRef, useState } from "react";
import selct_car from "../assets/images/selct_car.svg";
import sedan from "../assets/images/sedan.png";
import etios from "../assets/images/etios.png";
import suv from "../assets/images/suv.png";
import innova from "../assets/images/innova.png";
import { useForm } from "react-hook-form";
import { StandaloneSearchBox } from "@react-google-maps/api";
import axios from "axios";
import { AiOutlineCloseCircle } from "react-icons/ai";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { netbixTelegramChatId, telegramApi, telegramChatId,netbixTelegramApi,customerMobileNumber, front_url } from "../config/config";
import indiaFlag from "../assets/images/indiaflag.png";

const libraries = ["places"];

const Roundtrip = () => {
  const [selectedDate, setSelectedDate] = useState("");
  const [roundeDriverBeta, setRoundeDriverBeta] = useState(400);
  const [selectedReturnDate, setSelectedReturnDate] = useState(null);
  const [driverBeta, setDeriverBeta] = useState(0);
  const [carList, setCarList] = useState(false);
  const [listCars, setListCars] = useState({ car_amount: 0 });
  const [carReq, setCarReq] = useState(false);
  const [distanceResult, setDistanceResult] = useState({
    org_distance: null,
    org_duration: null,
  });
  const [google, setGoogle] = useState(null);
  const [whatsaappMsg, setWhatsaappMsg] = useState("");

  const [popup, setPopup] = useState(false);

  const [popupDis, setPopupDis] = useState(0);
  const [popupDur, setPopupDur] = useState("");
  const [popupCar, setPopupCar] = useState("");
  const [popupRate, setPopupRate] = useState(0);
  const [totRate, setTotalRate] = useState(0);
  function openCars() {
    setCarList(!carList);
  }
  const searchBox = useRef(null);

  const formRef = useRef(null);

  function sendWhatsapp() {
    document.body.classList.remove("dis_scroll");
    setPopup(false);
    window.open(`https://wa.me/+91${customerMobileNumber}?text=${whatsaappMsg}`);
  }

  const handlePlacesChanged = () => {
    const places = searchBox.current.getPlaces();
    console.log(places);
  };

  // const { isLoaded, loadError } = useLoadScript({
  //     googleMapsApiKey: 'AIzaSyCYfVYTCGKRhBNN5v6uIqb3C477IWcVcCU',
  //     libraries,
  // });

  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
  } = useForm();

  useEffect(() => {
    if (window.google) {
      setGoogle(window.google);
    }
  }, []);

  const onSubmit = async (data) => {
    console.log(data);
    console.log(selectedDate);

    if (listCars.car_amount === 0) {
      setCarReq(true); 
      return;
    } else {
      setCarReq(false);
    }
          if (
      listCars.car_amount !== "0" &&
      data.drop_add !== "" &&
      data.full_name !== "" &&
      data.pick_up_add !== "" &&
      selectedDate !== "" &&
      data.pickup_time !== "" &&
      selectedReturnDate !== ""
    ) {
      if (data.drop_add === data.pick_up_add) {
        return alert("Please select Different Places");
      }
      if (!google) {
        alert("Google Maps API is not loaded yet.");
        return;
      }
      setPopupCar(listCars.text);

      const distanceMatrixService = new google.maps.DistanceMatrixService();

      const distanceMatrixOptions = {
        origins: [data.pick_up_add],
        destinations: [data.drop_add],
        travelMode: "DRIVING",
      };

      await distanceMatrixService.getDistanceMatrix(
        distanceMatrixOptions,
        (response, status) => {
          if (
            status === "OK" &&
            response.destinationAddresses[0].length !== 0 &&
            response.originAddresses[0].length !== 0
          ) {
            if (
              response.rows[0].elements[0].distance.text ||
              response.rows[0].elements[0].duration.text
            ) {
              var org_distance = response.rows[0].elements[0].distance.text;
              var org_duration = response.rows[0].elements[0].duration.text;
              setPopupDur(org_duration);
              setPopupDis(org_distance);
              setDistanceResult({ org_distance, org_duration });

              if (org_distance != null && org_duration != null) {

                var day = selectedDate.getDate(); 
                console.log(day);
                var month = selectedDate.getMonth() + 1; 
                console.log(month);
            
                var year = selectedDate.getFullYear(); 
                console.log(year);
                var formattedDate = day + '/' + month + '/' + year;
                console.log(formattedDate); 

                var day1 = selectedReturnDate.getDate(); 
                console.log(day1);
                var month1 = selectedReturnDate.getMonth() + 1; 
                console.log(month1);
            
                var year1 = selectedReturnDate.getFullYear(); 
                console.log(year1);
                var formattedDate2 = day1 + '/' + month1 + '/' + year1;
                console.log(formattedDate2); 
                var today = formattedDate;
                today = new Date(
               today.toString().split("/")[2],
               today.toString(). split("/")[1] - 1,
               today.toString(). split("/")[0]
                );
                console.log(today); 
                var date2 = formattedDate2
                date2 = new Date(
                  date2.toString().split("/")[2],
                  date2.toString().split("/")[1] - 1,
                  date2.toString().split("/")[0]
                );
                console.log(date2); 
              // const pickupDate = new Date(year, month - 1, day);
              // const returnDate = new Date(year1, month1 - 1, day1);

                          var timeDiff = Math.abs(date2.getTime() - today.getTime());
                            console.log(timeDiff); 

                            var diffDays  = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
                            console.log(diffDays); 

                // var differenceMs = selectedReturnDate - selectedDate;
                // var differenceDays = Math.floor(differenceMs / (1000 * 60 * 60 * 24));
                
                // console.log("Difference in days:", differenceDays);
                var newdiffDays = diffDays + 1;
                if (newdiffDays === 1) {
                  var DriverBetaMul = newdiffDays * roundeDriverBeta;
                  setDeriverBeta(DriverBetaMul);
                  console.log(DriverBetaMul);
                  console.log(org_distance);
                    if (parseInt(org_distance) <= 250) {
                    one_way_rate = (250 * parseInt(listCars.car_amount));
                    setTotalRate(250 * parseInt(listCars.car_amount));
                    setPopupRate(parseInt(one_way_rate) + DriverBetaMul)
                    console.log(one_way_rate);
                    console.log(totRate);
                    console.log(popupRate);
                          }

                  else{
                  var one_way_rate = (parseInt(org_distance) * parseInt(listCars.car_amount));
                  setTotalRate((parseInt(org_distance) * parseInt(listCars.car_amount)));
                  setPopupRate(parseInt(one_way_rate) + DriverBetaMul)
                  console.log(listCars.car_amount);
                  console.log(one_way_rate);
                  console.log(totRate);
                  console.log(popupRate);
                  console.log(org_distance);
                }
                  var new_tot_trip_fair = parseInt(org_distance) <= 250 ? parseInt(one_way_rate) + DriverBetaMul 
                  : ((parseInt(one_way_rate)) + DriverBetaMul);
                  var new_rate_round_trip = parseInt(org_distance) <= 250 ? one_way_rate : one_way_rate;
                  console.log(new_rate_round_trip);

                } else {
                  console.log(roundeDriverBeta,'else');
                  console.log(newdiffDays,'else');

                  var DriverBetaMul = (newdiffDays * roundeDriverBeta);
                  setDeriverBeta(DriverBetaMul);
                  console.log(roundeDriverBeta);
                                    console.log(DriverBetaMul,'else');

                  var one_way_rate = ((250 * newdiffDays) * parseInt(listCars.car_amount));
                  setTotalRate(((250 * parseInt(listCars.car_amount)) * newdiffDays));
                  console.log(one_way_rate);

                  console.log(totRate,"else");
                  setPopupRate(parseInt(one_way_rate) + DriverBetaMul)
                  console.log(popupRate,"else");
                  var new_rate_round_trip = one_way_rate;
                  var new_tot_trip_fair = parseInt(one_way_rate) + DriverBetaMul;
                  console.log(new_tot_trip_fair,"else");

              }
              
                setWhatsaappMsg(
                  "Website Enquiry%0A******* *******%0AYour Booking Details:%0A%0AName : " +
                    data.full_name +
                    " ,%0A%0AMobile Number  : +91" +
                    data.mob_number +
                    " ,%0A%0APickup Location  : " +
                    data.pick_up_add +
                    " ,%0A%0ADrop Location : " +
                    data.drop_add +
                    ",%0A%0ATrip Type: Round Trip ,%0A%0APickup Date/Time: " +
                    formattedDate +
                    " " +
                    data.pickup_time +
                    ",%0A%0AReturn Date : " +
                    formattedDate2 +
                    " ,%0A%0ATotal KM : " +
                    org_distance +
                    " ,%0A%0ADuration : " +
                    org_duration +
                    " ,%0A%0ARate Per KM: " +
                    listCars.car_amount +
                    " ₹,%0A%0ADriver Beta : " +
                    DriverBetaMul +
                    " ₹  ,%0A%0ARate for Round Trip : " +
                    new_rate_round_trip +
                    "  ₹,%0A%0ATotal Trip Fare :" +
                    new_tot_trip_fair +
                    ` ₹ ,%0A%0AToll, parking, permit extra %0A%0AFor any questions please contact +91${customerMobileNumber} %0A%0A` +
                    front_url
                );

                var message =
                  "Website Enquiry\n******* *******\n\nYour Booking Details:\n\nName : " +
                  data.full_name +
                  " ,\n\nMobile Number  : +91" +
                  data.mob_number +
                  " ,\n\nPickup Location  : " +
                  data.pick_up_add +
                  " ,\n\nDrop Location : " +
                  data.drop_add +
                  ",\n\nTrip Type: Round Trip ,\n\nPickup Date/Time: " +
                  formattedDate +
                  " " +
                  data.pickup_time +
                  ",\n\nReturn Date : " +
                  formattedDate2 +
                  "  , \n\nTotal KM : " +
                  org_distance +
                  " ,\n\nDuration : " +
                  org_duration +
                  " ,\n\nRate Per KM: " +
                  listCars.car_amount +
                  " ₹,\n\nDriver Beta : " +
                  DriverBetaMul +
                  " ₹  ,\n\nRate for Round Trip : " +
                  new_rate_round_trip +
                  "  ₹,\n\nTotal Trip Fare :" +
                  new_tot_trip_fair +
                  ` ₹ ,\n\nToll, parking, permit extra \n\nFor any questions please contact +91${customerMobileNumber} \n\n` +
                  front_url;
                try {
                const response = axios.post(
                    `https://api.telegram.org/bot${telegramApi}/sendMessage`,
                    {
                        chat_id: telegramChatId,
                        text: message,
                    }
                    );
                    if (response) {
                      setListCars({
                        text: "",
                        imageSrc: "",
                        car_amount: 0,
                      });
                      formRef.current.reset();
                      document.body.classList.add("dis_scroll");
                      setPopup(true);
                      setSelectedDate(null);
                      setSelectedReturnDate(null);
                    }
                } catch (error) {
                  alert("Please try again");
                  console.error("Error sending Telegram message:", error);
                  // Handle the error
                }

                try {
                    axios.post(
                      `https://api.telegram.org/bot${netbixTelegramApi}/sendMessage`,
                      {
                        chat_id: netbixTelegramChatId,
                        text: message,
                      }
                    );
                    // if (response) {
                    //   setListCars({
                    //     text: "",
                    //     imageSrc: "",
                    //     car_amount: 0,
                    //   });
                    //   formRef.current.reset();
                    //   document.body.classList.add("dis_scroll");
                    //   setPopup(true);
                    //   setSelectedDate(null);
                    //   setSelectedReturnDate(null);
                    // }
  
                  }
                   catch (error) {
                    console.error("Error sending Telegram message:", error);
                    // Handle the error
                  }
              
              } else {
                alert("Please Try again!");
              }
            }
          } else {
            console.error("Error:", status);
          }
        }
      );
    }
  };
  function popUpClose() {
    document.body.classList.remove("dis_scroll");
    setPopup(false);
  }
  const selectCar = (text, imageSrc, car_amount, carDriverBeta) => {
    setListCars({
      text,
      imageSrc,
      car_amount,
    });
    setRoundeDriverBeta(carDriverBeta);
    setCarList(false);
  };

  return (
    < div className="mt-6 ">
< form ref={formRef} onSubmit={handleSubmit(onSubmit)}   >   
  <div className="grid grid-cols-1">
          <div className="p-3 pb-0">
          
            <StandaloneSearchBox
              onLoad={(ref) => (searchBox.current = ref)}
              onPlacesChanged={handlePlacesChanged}
            >
              <input
                type="text"
                className={`w-full py-3 px-4 focus:outline-0 border  ${
                  errors.pick_up_add ? " border-red-400" : "border-b-0"
                }`}
                placeholder="Enter your pickup point"
                name="pick_up_add"
                {...register("pick_up_add", { required: true })}
              />
            </StandaloneSearchBox>
          </div>
          <div className="p-3 pt-0">
            <StandaloneSearchBox
              onLoad={(ref) => (searchBox.current = ref)}
              onPlacesChanged={handlePlacesChanged}
            >
              <input
                type="text"
                className={`w-full py-3 px-4 focus:outline-0 border  ${
                  errors.drop_add &&
                  errors.pick_up_add &&
                  " border-red-400 border-t-0"
                } ${
                  errors.drop_add && !errors.pick_up_add && " border-red-400 "
                }`}
                placeholder="Enter your drop point"
                name="drop_add"
                {...register("drop_add", { required: true })}
              />
            </StandaloneSearchBox>
          </div>
          <div className=" grid grid-cols-2 border mx-3 bg-black">
            <div className="px-3">
              <DatePicker
                selected={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                dateFormat="dd/MM/yyyy"     
                minDate={new Date()}
                className={`w-full py-3 px-4 focus:outline-0 ${
                  !selectedDate &&
                  errors.pickup_dates &&
                  "border border-red-500"
                }`}
                placeholderText="Pick up Date"
                name="pickup_date"
              />


              <input
                type="hidden"
                value={selectedDate?selectedDate:' '}
                name="pickup_dates"
                {...register("pickup_dates", { required: true })}
              />
            </div>
            <div className="px-3">
              <select
                className={`w-full py-3 px-4 focus:outline-0 ${
                  errors.pickup_time && " border border-red-500"
                }`}
                id="pickup_time"
                name="pickup_time"
                {...register("pickup_time", { required: true })}
              >
                <option value="">Pickup Time</option>

                <option value="12:00 AM">12:00 AM</option>

                <option value="12:30 AM">12:30 AM</option>

                <option value="01:00 AM">01:00 AM</option>

                <option value="01:30 AM">01:30 AM</option>

                <option value="02:00 AM">02:00 AM</option>

                <option value="02:30 AM">02:30 AM</option>

                <option value="03:00 AM">03:00 AM</option>

                <option value="03:30 AM">03:30 AM</option>

                <option value="04:00 AM">04:00 AM</option>

                <option value="04:30 AM">04:30 AM</option>

                <option value="05:00 AM">05:00 AM</option>

                <option value="05:30 AM">05:30 AM</option>

                <option value="06:00 AM">06:00 AM</option>

                <option value="06:30 AM">06:30 AM</option>

                <option value="07:00 AM">07:00 AM</option>

                <option value="07:30 AM">07:30 AM</option>

                <option value="08:00 AM">08:00 AM</option>

                <option value="08:30 AM">08:30 AM</option>

                <option value="09:00 AM">09:00 AM</option>

                <option value="09:30 AM">09:30 AM</option>

                <option value="10:00 AM">10:00 AM</option>

                <option value="10:30 AM">10:30 AM</option>

                <option value="11:00 AM">11:00 AM</option>

                <option value="11:30 AM">11:30 AM</option>

                <option value="12:00 PM">12:00 PM</option>

                <option value="12:30 PM">12:30 PM</option>

                <option value="01:00 PM">01:00 PM</option>

                <option value="01:30 PM">01:30 PM</option>

                <option value="02:00 PM">02:00 PM</option>

                <option value="02:30 PM">02:30 PM</option>

                <option value="03:00 PM">03:00 PM</option>

                <option value="03:30 PM">03:30 PM</option>

                <option value="04:00 PM">04:00 PM</option>

                <option value="04:30 PM">04:30 PM</option>

                <option value="05:00 PM">05:00 PM</option>

                <option value="05:30 PM">05:30 PM</option>

                <option value="06:00 PM">06:00 PM</option>

                <option value="06:30 PM">06:30 PM</option>

                <option value="07:00 PM">07:00 PM</option>

                <option value="07:30 PM">07:30 PM</option>

                <option value="08:00 PM">08:00 PM</option>

                <option value="08:30 PM">08:30 PM</option>

                <option value="09:00 PM">09:00 PM</option>

                <option value="09:30 PM">09:30 PM</option>

                <option value="10:00 PM">10:00 PM</option>

                <option value="10:30 PM">10:30 PM</option>

                <option value="11:00 PM">11:00 PM</option>

                <option value="11:30 PM">11:30 PM</option>
              </select>
            </div>
          </div>
          {/* <div className=" grid grid-cols-2 border mx-3 mt-3">
            <div className=" relative">
              <span className=" absolute top-1/2 left-2 -translate-y-1/2 text-white">
                <FaUser className=" " />
              </span>
              <select
                className={`w-full px-4 pl-7  py-3 focus:outline-0 appearance-none ${
                  errors.passengers && " border border-red-500"
                }`}
                id="pickup_time"
                name="passengers"
                {...register("passengers", { required: true })}
              >
                <option selected value="1">
                  1
                </option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
              </select>
            </div>
            <div className=" relative">
              <span className=" absolute top-1/2 left-2 -translate-y-1/2 text-white">
                <MdLuggage size={20} className=" " />
              </span>
              <select
                className={`w-full px-4 pl-7  py-3 focus:outline-0 appearance-none ${
                  errors.luggages && " border border-red-500"
                }`}
                id="pickup_time"
                name="luggages"
                {...register("luggages", { required: true })}
              >
                <option selected value="0">
                  0
                </option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
              </select>
            </div>
          </div> */}
            <div className="p-3 form_fade">
              <DatePicker
                selected={selectedReturnDate}
                onChange={(date) => setSelectedReturnDate(date)}
                dateFormat="dd/MM/yyyy"     
                minDate={new Date()}
                className={`w-full py-3 px-4 focus:outline-0 ${
                  !selectedReturnDate &&
                  errors.return_date &&
                  "border border-red-500"
                }`}
                placeholderText="Return Date"
                name="return_date"
              />


              <input
                type="hidden"
                value={selectedReturnDate?selectedReturnDate:' '}
                name="return_date"
                {...register("return_date", { required: true })}
              />
            </div>
          <div className="p-3">
            <div className="relative">
              <span
                className={`top-1/2 left-0 h-full px-2 border text-[16px] absolute -translate-y-1/2 flex gap-x-1 items-center ${
                  errors.mob_number && "border border-red-500"
                }`}
              >
                <span>
                  <img src={indiaFlag} className="w-6" />
                </span>
                <span className=" text-white">+91</span>
              </span>
              <input
                type="int"
                className={`w-full border  py-3 px-4 focus:outline-0 pl-20 ${
                  errors.mob_number && "border border-red-500"
                }`}
                {...register("mob_number",
                 { required: "Mobile Number is required"  ,minLength:{value:10,message:"Requied 10 digit"},
                 maxLength:{value:10,message:"Requied only 10 digit"}
                 })}
                placeholder="Mobile number"
                name="mob_number"
              />
              
              
            </div>
            {errors.mob_number ? <span className='mt-2 inline-block  bg-red-500 w-full p-1 px-2 rounded-md text-white'>Mobile Number Should Be 10</span>:''}

          </div>

          <div className="p-3">
            <input
              type="text"
              className={`w-full border  py-3 px-4 focus:outline-0 ${
                errors.full_name && "border border-red-500"
              }`}
              placeholder="Enter your Name"
              name="full_name"
              {...register("full_name", { required: true })}
            />
          </div>
        </div>
        <div className="p-3">
          <input
            type="hidden"
            name="car_amount"
            value={listCars.car_amount != 0 ? listCars.car_amount : 0}
          />
          <div
            className={`p-3 px-4 cursor-pointer relative border bg-black text-white ${
              carReq && "border-red-500"
            }`}
            onClick={openCars}
          >
            {listCars.text ? listCars.text : "Select Car Type"}{" "}
            <img
              src={listCars.imageSrc ? listCars.imageSrc : selct_car}
              alt="select car"
              className=" w-[35px] h-auto absolute top-1/2 right-4 -translate-y-1/2"
            />
          </div>
          <ul className={`bg-black text-white border ${carList ? "" : "hidden"}`}>
            <li
              className="py-3 px-4 border-b-2 relative cursor-pointer"
              onClick={() => selectCar("SEDAN", sedan, 13, 300)}
            >
              SEDAN{" "}
              <img
                src={sedan}
                alt="sedan"
                className="absolute top-1/2 right-4 -translate-y-1/2 w-[35px] h-auto"
              />
            </li>
            <li
              className="py-3 px-4 border-b-2 relative cursor-pointer"
              onClick={() => selectCar("ETIOS", etios, 13, 400)}
            >
              ETIOS{" "}
              <img
                src={etios}
                alt="etios"
                className="absolute top-1/2 right-4 -translate-y-1/2 w-[35px] h-auto"
              />
            </li>
            <li
              className="py-3 px-4 border-b-2 relative cursor-pointer"
              onClick={() => selectCar("SUV", suv, 18, 400)}
            >
              SUV{" "}
              <img
                src={suv}
                alt="suv"
                className="absolute top-1/2 right-4 -translate-y-1/2 w-[35px] h-auto"
              />
            </li>
            <li
              className="py-3 px-4 border-b-2 relative cursor-pointer"
              onClick={() => selectCar("INNOVA", innova, 18, 400)}
            >
              INNOVA{" "}
              <img
                src={innova}
                alt="innova"
                className="absolute top-1/2 right-4 -translate-y-1/2 w-[35px] h-auto"
              />
            </li>
          </ul>
          {carReq?
            
            <span className="mt-2 inline-block  bg-red-500 w-full p-1 px-2 rounded-md text-white">
              Car is required.
            </span>: " "
          }

        </div>
        <div className="text-center mt-5">
          <button className="mt-4py-3 px-6 th-btn uppercase">
            Get Estimation
          </button>
        </div>
      </form>
      {/* <form ref={formRef} onSubmit={onSubmit}>
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="p-3">
            <input
              type="text"
              className="w-full py-3 px-4 border border-gray-600"
              placeholder="Full Name"
              name="full_name"
              {...register("full_name", { required: true })}
            />
            {errors.full_name && (
              <span className="mt-2 inline-block  bg-red-500 w-full p-1 px-2 rounded-md text-white">
                Name is required.
              </span>
            )}
          </div>
          <div className="p-3">
            <input
              type="number"
              className="w-full py-3 px-4 border border-gray-600"
              placeholder="Mobile number"
              name="mob_number"
              {...register("mob_number", { required: true })}
            />
            {errors.mob_number && (
              <span className="mt-2 inline-block  bg-red-500 w-full p-1 px-2 rounded-md text-white">
                Mobile Number is required.
              </span>
            )}
          </div>
          <div className="p-3">
            <StandaloneSearchBox
              onLoad={(ref) => (searchBox.current = ref)}
              onPlacesChanged={handlePlacesChanged}
            >
              <input
                type="text"
                className="w-full py-3 px-4 border border-gray-600"
                placeholder="Pick up address"
                name="pick_up_add"
                {...register("pick_up_add", { required: true })}
              />
            </StandaloneSearchBox>
            {errors.pick_up_add && (
              <span className="mt-2 inline-block  bg-red-500 w-full p-1 px-2 rounded-md text-white">
                Pick up Address is required.
              </span>
            )}
          </div>
          <div className="p-3">
            <StandaloneSearchBox
              onLoad={(ref) => (searchBox.current = ref)}
              onPlacesChanged={handlePlacesChanged}
            >
              <input
                type="text"
                className="w-full py-3 px-4 border border-gray-600"
                placeholder="Drop off address"
                name="drop_add"
                {...register("drop_add", { required: true })}
              />
            </StandaloneSearchBox>
            {errors.drop_add && (
              <span className="mt-2 inline-block  bg-red-500 w-full p-1 px-2 rounded-md text-white">
                Drop Adress is required.
              </span>
            )}
          </div>
          <div className="p-3">
            <DatePicker
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              dateFormat="dd/MM/yyyy"
              className="w-full py-3 px-4 border border-gray-600"
              placeholderText="Pick up Date"
              minDate={new Date()}
              name="pickup_date"
            />
            <input
              type="hidden"
              value={selectedDate}
              name="pickup_dates"
              {...register("pickup_dates", { required: true })}
            />

            {!selectedDate && errors.pickup_dates && (
              <span className="mt-2 inline-block  bg-red-500 w-full p-1 px-2 rounded-md text-white">
                Pickup Date is required.
              </span>
            )}
          </div>
          <div className="p-3">
            <select
              className="w-full py-3 px-4 border border-gray-600"
              id="pickup_time"
              name="pickup_time"
              {...register("pickup_time", { required: true })}
            >
              <option value="">Pickup Time</option>

              <option value="12:00 AM">12:00 AM</option>

              <option value="12:30 AM">12:30 AM</option>

              <option value="01:00 AM">01:00 AM</option>

              <option value="01:30 AM">01:30 AM</option>

              <option value="02:00 AM">02:00 AM</option>

              <option value="02:30 AM">02:30 AM</option>

              <option value="03:00 AM">03:00 AM</option>

              <option value="03:30 AM">03:30 AM</option>

              <option value="04:00 AM">04:00 AM</option>

              <option value="04:30 AM">04:30 AM</option>

              <option value="05:00 AM">05:00 AM</option>

              <option value="05:30 AM">05:30 AM</option>

              <option value="06:00 AM">06:00 AM</option>

              <option value="06:30 AM">06:30 AM</option>

              <option value="07:00 AM">07:00 AM</option>

              <option value="07:30 AM">07:30 AM</option>

              <option value="08:00 AM">08:00 AM</option>

              <option value="08:30 AM">08:30 AM</option>

              <option value="09:00 AM">09:00 AM</option>

              <option value="09:30 AM">09:30 AM</option>

              <option value="10:00 AM">10:00 AM</option>

              <option value="10:30 AM">10:30 AM</option>

              <option value="11:00 AM">11:00 AM</option>

              <option value="11:30 AM">11:30 AM</option>

              <option value="12:00 PM">12:00 PM</option>

              <option value="12:30 PM">12:30 PM</option>

              <option value="01:00 PM">01:00 PM</option>

              <option value="01:30 PM">01:30 PM</option>

              <option value="02:00 PM">02:00 PM</option>

              <option value="02:30 PM">02:30 PM</option>

              <option value="03:00 PM">03:00 PM</option>

              <option value="03:30 PM">03:30 PM</option>

              <option value="04:00 PM">04:00 PM</option>

              <option value="04:30 PM">04:30 PM</option>

              <option value="05:00 PM">05:00 PM</option>

              <option value="05:30 PM">05:30 PM</option>

              <option value="06:00 PM">06:00 PM</option>

              <option value="06:30 PM">06:30 PM</option>

              <option value="07:00 PM">07:00 PM</option>

              <option value="07:30 PM">07:30 PM</option>

              <option value="08:00 PM">08:00 PM</option>

              <option value="08:30 PM">08:30 PM</option>

              <option value="09:00 PM">09:00 PM</option>

              <option value="09:30 PM">09:30 PM</option>

              <option value="10:00 PM">10:00 PM</option>

              <option value="10:30 PM">10:30 PM</option>

              <option value="11:00 PM">11:00 PM</option>

              <option value="11:30 PM">11:30 PM</option>
            </select>
            {errors.pickup_time && (
              <span className="mt-2 inline-block  bg-red-500 w-full p-1 px-2 rounded-md text-white">
                Return Date is required.
              </span>
            )}
          </div>
          <div className="p-3">
            <DatePicker
              selected={selectedReturnDate}
              onChange={(date) => setSelectedReturnDate(date)}
              dateFormat="dd/MM/yyyy"
              className="w-full py-3 px-4 border border-gray-600"
              minDate={new Date()}
              placeholderText="Return Date"
              name="return_date"
            />
            <input
              type="hidden"
              value={selectedReturnDate}
              name="return_dates"
              {...register("return_dates", { required: true })}
            />
            {!selectedReturnDate && errors.return_dates && (
              <span className="mt-2 inline-block  bg-red-500 w-full p-1 px-2 rounded-md text-white">
                Pickup Date is required.
              </span>
            )}
          </div>
        </div>
        <div className="p-3">
          <input
            type="hidden"
            name="car_amount"
            value={listCars.car_amount != 0 ? listCars.car_amount : 0}
          />
          <div
            className="p-3 px-4 bg-white cursor-pointer relative border border-gray-600"
            onClick={openCars}
          >
            {listCars.text ? listCars.text : "Select car Type"}{" "}
            <img
              src={listCars.imageSrc ? listCars.imageSrc : selct_car}
              alt="select car"
              className=" w-[35px] h-auto absolute top-1/2 right-4 -translate-y-1/2"
            />
          </div>
         
          {carReq ? (
            <span className="mt-2 inline-block  bg-red-500 w-full p-1 px-2 rounded-md text-white">
              Car is required.
            </span>
          ) : (
            ""
          )}
        </div>
        <div className="text-center mt-5">
          <button className="bg-[#e30d16] mt-4 md:mt-6 text-white py-3 px-6  text-[16px] sm:text-[17px] md:text-[18px] inline-block uppercase hover:bg-black transition-all ease-linear">
            Get Estimation
          </button>
        </div>
      </form> */}
      {popup ? (
        <div className="fixed top-0 left-0  h-full w-full z-[100]">
          <div className="w-[90%] md:w-3/5 min-h-[200px] bg-gray-400 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 absolute p-5">
            <span
              className="fixed top-2 right-2 cursor-pointer"
              onClick={popUpClose}
            >
              <AiOutlineCloseCircle className="text-[20px]" />
            </span>
            <h2 className="text-[30px]">
              Trip Estimation{" "}
              <span className="primary-color pop-shadow"> Rs.{popupRate}</span>
            </h2>
            <div>
              <div className="grid grid-cols-2 py-3 mt-4 ">
                <div className="pl-2 border-b-2 border-t-2 border-l-2 py-3">
                  Total Distance :
                </div>
                <div className="pr-2 border-b-2  border-t-2 border-r-2 border-l-2 px-2 py-3">
                  {popupDis}
                </div>
                <div className="pl-2 border-b-2 border-l-2 py-3">
                  Total Duration
                </div>
                <div className="pr-2 border-b-2 border-r-2 border-l-2 px-2 py-3">
                  {popupDur}
                </div>
                <div className="pl-2 border-b-2 border-l-2 py-3">
                  Selected Car Type
                </div>
                <div className="pr-2 border-b-2 border-r-2 border-l-2 px-2 py-3">
                  {popupCar}
                </div>
                <div className="pl-2 border-b-2 border-l-2 py-3">
                  Driver Allowance :
                </div>
                <div className="pr-2 border-b-2 border-r-2 border-l-2 px-2 py-3">
                  Included
                </div>
                <div className="pl-2 border-b-2 border-l-2 py-3">
                  Total Amount :
                </div>
                <div className="pr-2 border-b-2 border-r-2 border-l-2 px-2 py-3">
                  Rs. {totRate}
                </div>
                <div className="pl-2 border-b-2 border-l-2 py-3">
                  Driver Beta :
                </div>
                <div className="pr-2 border-b-2 border-r-2 border-l-2 px-2 py-3">
                  Rs. {roundeDriverBeta}
                </div>
                <div className="pl-2 border-b-2 border-l-2 py-3">
                  Total Driver Beta :
                </div>
                <div className="pr-2 border-b-2 border-r-2 border-l-2 px-2 py-3">
                  Rs. {driverBeta}
                </div>
              </div>
              <div className="mt-4 text-center">
                <button
                  onClick={sendWhatsapp}
                  className="th-btn-reverse py-3 text-white px-6 rounded-md"
                >
                  Book Now
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Roundtrip;
