import React from 'react'
import about from "../assets/about.jpg";
import { FaPhone } from 'react-icons/fa6';
const About = () => {
    return (
        <div  id='about'>
            <div className='container py-5 mx-auto px-4 md:px-8 lg:px-16'>
                <div className='block md:flex py-5 gap-x-20 items-center'>
                    <div className='w-full md:w-1/2'>
                        <div className=' relative'>
                            <img src={about} alt="about" className='mx-auto w-[500px] car_outline' />
                        </div>
                    </div>
                    <div className='mt-10 md:mt-0 px-3 w-full md:w-1/2'>
                        <h4 className=' text-lg font-medium primary-color'>About Us</h4>
                        <div className='text-3xl md:text-4xl font-bold mt-4'>No.1 Outstation taxi service in South India!</div>
                        <p className='text-black opacity-60 md:text-lg mt-5'>We believe that success is progress. I Drop Taxis has been providing hassle-free taxi services at their best. We provide taxi services like outstation cabs, round trip taxi, airport taxi, one way taxi, and drop taxis in some major cities like Coimbatore, Chennai, Bangalore, Madurai Salem Trichy, and other cities of South India. Upgrade to the I Drop Taxis to book your taxi and enjoy the offers with hassle-free rides.</p>
                        <div className=' mt-5'>
                        <a
                href="tel:+919443698657"
                className=" rounded-md th-btn-reverse hover:bg-black transition-all ease-linear px-3 py-2"
              >
                <span className=" flex gap-x-2 items-center justify-center">
                  <FaPhone size={17} /> +91 9443698657
                </span>
              </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About